import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'
import PageHeader from '../components/PageHeader/PageHeader'

const articleTemplate = ( { data: { strapi: { article } }, location } ) => {

	return (
		<>
			<SEO title={ article.title } description={ article.seoDescription } pathname={ location.pathname } />

			<PageHeader heading={ article.title } />

			{ article && article.content &&
				<DynamicZone components={ article.content } />
			}
		</>
	)
}

export default articleTemplate

export const query = graphql`
	query articleQuery($id: ID!) {
		strapi {
			article(id: $id) {
				id
				title
				content {
					... on StrapiQuery_ComponentArticleTextBlock {
						id
						text
					}
					... on StrapiQuery_ComponentArticleImage {
						id
						title
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_withWebp_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentArticleQuote {
						id
						author
						quote
					}
					... on StrapiQuery_ComponentCommonAccordion {
						accordionItem {
							answer
							question
						}
					}
					... on StrapiQuery_ComponentCommonAboutInfo {
						id
						aboutInfoTitle
						aboutInfoDescription
						aboutInfoReverse
						aboutInfoLinkUrl
						aboutInfoLinkLabel
						aboutInfoLinkExternal
						aboutInfoImage {
							id
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 750) {
										...GatsbyImageSharpFluid_withWebp_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonImage {
						id
						imageLinkUrl
						imageLinkLabel
						imageLinkExternal
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										aspectRatio
										...GatsbyImageSharpFluid_withWebp_noBase64
									}
								}
							}
						}
					}
				}
			}
		}
	}
`